.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 15px;
}

.absolute-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 101;
}

.staff-audio-in {
  margin-top: 20px;
}

.autocomplete-dropdown-wrap {
  position: relative;

  .autocomplete-dropdown-container {
    position: absolute;
    color: $black;
    top: 60px;
    max-height: 150px;
    width: 100%;
    text-align: left;
    font-size: 16px;
    z-index: 1000;
    box-shadow: none;
    background-color: #f5f5f5;
    border-radius: 8px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    overflow: auto;

    >div {
      padding: 13px 10px;

      &.suggestion-item {
        cursor: pointer;
        padding: 2px 5px;
      }

      &.suggestion-item--active {
        color: #41a9c7;
        padding: 2px 5px;
        background-color: #fafafa;
      }
    }
  }
}

// Contract module --
.sign-contract-rte {
  margin-top: 20px;
}

.rte-editor-wrapper {
  height: 300px;
  max-height: 100%;
}

// .rich-text-editor{
//   .public-DraftEditor-content{
//     height: 150px;
//     overflow: auto;
//   }
// }

.contract-form .makeStyles-formControl-320 {
  padding-top: 15px;
}

.contract-form .makeStyles-formControl-320 .MuiInputLabel-formControl {
  top: 0;
}

// ! Contact module

// User Module
.paymentMethodsWrap {
  text-align: left;
  margin-top: 10px;

  div {
    margin-top: 5px;

    label {
      color: #000000;
      margin-right: 25px;
    }
  }

  input[type="checkbox"i] {
    height: 20px;
    width: 20px;
    // accent-color: #41a9c7;
    margin: 0 5px 0 0;
    vertical-align: bottom;
  }
}

// Event Status
.eventStatusGreen,
.eventStatusRed {
  padding: 5px;
  border-radius: 15px;
  height: 25px;
  width: 25px;
  display: inline-block;
}

.eventStatusGreen {
  color: green;
  background: green;
}

.eventStatusRed {
  color: red;
  background: red;
}

.eventStatusPaid {
  color: green;

}

.eventStatusOpen {
  color: red;

}

.text-align {
  &--left {
    text-align: left !important;
  }
  &--center {
    text-align: center !important;
  }
  &--right {
    text-align: right !important;
  }
}

.contract-editor {
  height: 500px;
}

/**/
.contract-details{
  height: 475px;
  overflow: auto;
}

.empty-list-notice {
  padding: 20px 0;
  font-size: 16px;
}
